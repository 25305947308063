<template>
  <div ref="excel" class="excel">
  </div>
</template>

<script>
import axios from 'axios'
import utility from '@/common/utility'

export default {
  name: 'Excel',
  methods: {
    initialize (el) {
      // 初始化spread，目前只开一个sheet页
      this.spread = new GC.Spread.Sheets.Workbook(el, { calcOnDemand: true, sheetCount: 0 })
      // 设置右键菜单不可以使用
      this.spread.options.allowContextMenu = false
      // 设置右键菜单可用项目
      this.spread.contextMenu.menuData = this.spreadMenus
      // 设置不可粘贴excel样式
      this.spread.options.allowCopyPasteExcelStyle = false
      // 设置不可新增sheet页
      this.spread.options.newTabVisible = false

      this.spread.commandManager().setShortcutKey('clearAndEditing', GC.Spread.Commands.Key.del, false, false, false, false)

      axios.get('/excel/demo.xlsx?r=' + utility.getUuid(), {
        responseType: 'blob'
      })
        .then(res => {
          const excelIo = new GC.Spread.Excel.IO()
          excelIo.open(res.data, json => {
            console.log(json)
            this.spread.fromJSON(json)
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    mounted () {
      this.initialize(this.$refs.excel)
    }
  },
  mounted () {
    this.initialize(this.$refs.excel)
  }
}
</script>

<style scoped lang="scss">
.excel {
  width: 100%;
  height: 100%;
}
</style>
